// define jQuery
jQuery(function($){

let artist = (function(){
	let pub = {}

	const $trigger = $('.artist-trigger')
  const popupTemplate = `
    <div class="artist">
      <div class="artist__overlay"></div>
      <article class="artist__popup">
        <div class="artist__close">Close <span class="iconfont iconfont-close"></span></div>
        <div class="artist__content-wrapper">
          <div class="artist__gallery"></div>
          <div class="artist__detail">
            <div class="artist__detail__info"></div>
            <div class="artist__detail__links"></div>
          </div>
        </div>
      </article>
    </div>
  `

  const setGallery = () => {
    $('.artist__gallery').flickity({
      contain: true,
      imagesLoaded: true,
      draggable: true,
      wrapAround: true,
      prevNextButtons: true,
      selectedAttraction: 0.2,
      friction: 0.8,
      on: {
        ready: function() {
          $('.artist').addClass('active')
        }
      }
    })
  }

  const populateArtirst = artist => {
    const $info = $('.artist__detail__info')
    // add Title
    if (artist.title) {
      $info.append($(`<h2 class="artist__name">${artist.title}</h2>`))
    }
    // add Stage
    if (artist.stage) {
      $info.append($(`<p class="artist__stage">Stage: <a href="${artist.stageLink}" class="artist__stage__link">${artist.stage}</a></p>`))
    }
    // add Time
    if (artist.gigTime) {
      $info.append($(`<p class="artist__time">Time: ${artist.gigTime}</p>`))
    }
    // add Description
    if (artist.description) {
      $info.append($(`<div class="artist__description">${artist.description}</div>`))
    }
    // add links
    if (artist.socialLinks && artist.socialLinks.length) {
      for (let i = 0; i < artist.socialLinks.length; i++) {
        const link = artist.socialLinks[i]
        const url = link.artist_social_link
        const platform = link.artist_social_platform
        let copy = '';
        switch(link.artist_social_platform) {
          case 'instagram': copy = 'Discover in instagram'; break;
          case 'facebook': copy = 'Discover in facebook'; break;
          case 'soundcloud': copy = 'Listen in soundcloud'; break;
          case 'spotify': copy = 'Listen in spotify'; break;
        }
        $('.artist__detail__links')
          .append($(`<a href="${url}" target="_blank" rel="noopener" class="artist__detail__link"><span class="iconfont iconfont-${platform}"></span>${copy}</a>`))
      }
    }
    // add images
    if (artist.gallery && artist.gallery.length) {
      for (let i = 0; i < artist.gallery.length; i++) {
        $('.artist__gallery').append($(`<img src="${artist.gallery[i].sizes.artist_thumb}" class="artist__gallery__thumb" >`))
      }
      if (artist.gallery.length > 1) setGallery()
      else $('.artist').addClass('active')
    }
  }

	pub.openArtist = (e, $target) => {
		e.preventDefault()
		const artist = $target.data('artist')
    const artistUrl = `${window.location.origin}/wp-json/wp/v2/line-up/${artist}`

    $('.artist').remove()

    $.ajax({
      url: artistUrl,
      type: "GET",
      beforeSend: function(xhr) {
        $('body').append($(popupTemplate))
      },
      success: function(data) {
        const artist = {
          title: data.title.rendered || null,
          description: data.content.rendered || null,
          stage: data.acf_fields.stage_artists.post_title || null,
          stageLink: `/stages-and-areas/#${data.acf_fields.stage_artists.post_name}`,
          gigTime: data.acf_fields.playing_time || null,
          socialLinks: data.acf_fields.artist_links || null, // [] - each - artist_social_link / artist_social_platform
          gallery: data.acf_fields.artist_images || null, // [] - each - sizes - artist_gallery_thumb
        }
        populateArtirst(artist)
        window.location.hash = `#${data.slug}`
      }
    });
	}

	const eventListeners = () => {
		$trigger.click(function(e) {
      if($('.artist').length) $('.artist').remove()
			pub.openArtist(e, $(this))
		})
    $(document).on('click', '.artist__close, .artist__overlay', function() {
      history.pushState("", document.title, window.location.pathname)
      if($('.artist').length) $('.artist').fadeOut(500, function() {
        $('.artist').remove()
      });
    })
	}

  const showOnLoad = () => {
    const trigger = window.location.hash.replace('#', '')
    const $trigger = $(`.artist-trigger[data-slug="${trigger}"]`)
    if ($trigger.length) $trigger.trigger('click')
  }

	pub.init = () => {
		eventListeners()
    if (window.location.hash) showOnLoad()
	}

	return pub
})()
let gallery = (function(){
  let pub = {}

  const $wrapper = $('.gallery__thumbs');
	const $items = $('.gallery__thumb');

  const options = {
		itemSelector: '.gallery__thumb',
		percentPosition: true,
		masonry: {
			// use element for option
			columnWidth: '.gallery__sizer'
		}
	}

  const setupGrid = () => {
		$items.imagesLoaded( function() {
			$wrapper.isotope(options)
		})
	}

  pub.init = () => {
    setupGrid()
	}

	return pub
})()
let highlights = (function(){
	let pub = {}

	const $wrapper = $('.home__highlights__carousel')

	const highlightOptions = {
		draggable: true,
		wrapAround: false,
		imagesLoaded: true,
		prevNextButtons: true,
		pageDots: false,
	}

	const setCarousel = () => $wrapper.flickity(highlightOptions)

	pub.init = () => {
		setCarousel()
	}

	return pub
})()
let info = (function(){
	let pub = {}

	const $trigger = $('.info__nav__item')
	const $contentWrapper = $('.info__content')
	const $contentItem = $('.info__content__item')
  const $close = $('.info__content__close')

	const showContent = $target => {
		const infoItem = $target.data('trigger');
    const $infoItem = $(`.info__content__item[data-target="${infoItem}"]`)
		$trigger.removeClass('active')
		$target.addClass('active')
    $contentItem.removeClass('active')
		$infoItem.addClass('active')
    $contentWrapper.addClass('active')

    if (window.history.pushState) {
      const newurl = `${window.location.origin}${window.location.pathname}#${infoItem}`;
      window.history.pushState({path:newurl},'',newurl);
    }
	}

  pub.hideContent = () => {
    $trigger.removeClass('active')
    $contentWrapper.removeClass('active')
    $('.info__content__item').removeClass('active')
    history.pushState("", document.title, window.location.pathname)
  }

	const eventListeners = () => {
		$trigger.click(function(e) {
			showContent($(this))
		})
    $close.click(function() {
			pub.hideContent()
		})
	}
  pub.showFirst = () => $trigger.first().trigger('click')

  const showFromUrl = () => {
    const target = window.location.hash.replace('#', '')
    const $target = $(`.info__nav__item[data-trigger="${target}"]`)
    $target.trigger('click')
  }

	pub.init = () => {
		eventListeners()

    if (window.location.hash) showFromUrl()
    else if (window.windowWidth >= 1024) pub.showFirst()
	}

	return pub
})()
let lineup = (function(){
	let pub = {}

	const $trigger = $('.lineup__filters__trigger')
	const $thumb = $('.lineup__artist-thumb')

	const filterArtists = (e, $target) => {
		e.preventDefault()
		const filter = $target.attr('href').replace('#', '')
		$trigger.removeClass('active')
		$target.addClass('active')
		if (filter === 'all') {
			$thumb.removeClass('hide')
			return
		} else {
			$thumb.addClass('hide')
			$(`.lineup__artist-thumb[data-stage="${filter}"]`).removeClass('hide')
		}
	}

	const eventListeners = () => {
		$trigger.click(function(e) {
			filterArtists(e, $(this))
		})
	}

	pub.init = () => {
		eventListeners()
	}

	return pub
})()
let music = (function(){
	let pub = {}

	const $gallery = $('.music__gallery')
	const $quotes = $('.music__quotes__gallery')

  const galleryOptions = {
    contain: true,
    imagesLoaded: true,
		draggable: true,
		wrapAround: true,
		prevNextButtons: true,
    selectedAttraction: 0.2,
    friction: 0.8
  }

  const setSlides = () => {
    $gallery.each(function() {
      const $this = $(this)
      const $slides = $this.find('.music__gallery__slide')
      if ($slides.length) $this.flickity(galleryOptions)
    })

    $quotes.each(function() {
      const $this = $(this)
      const $slides = $this.find('.music__quotes__gallery__slide')
      if ($slides.length) $this.flickity(galleryOptions)
    })
  }

	pub.init = () => {
    setSlides()
	}

	return pub
})()
let nav = (function(){
	let pub = {}

	const $wrapper = $('.navigation')
	const $burger = $('.navigation__burger')
	const $sideNav = $('.navigation__side-nav')
	const $close = $('.navigation__side-nav__close')

	const openClose = e => {
		e.preventDefault()
		$sideNav.toggleClass('active')
	}

	const changeStyle = () => {
		const scroll = $(window).scrollTop()

		if (scroll >= 200) $wrapper.addClass('sticky')
		else $wrapper.removeClass('sticky')
	}

	const eventListeners = () => {
		$burger.add($close).click(e => openClose(e))
		$(window).scroll(() => changeStyle())
	}

	pub.init = () => {
		eventListeners()
		changeStyle()
	}

	return pub
})()
let areas = (function(){
	let pub = {}

	const $navItem = $('.areas__hero__nav__item')
	const $gallery = $('.areas__area__gallery')
	const $area = $('.areas__area')
  const $artistTrigger = $('.areas__area__artist')

  const galleryOptions = {
    imagesLoaded: true,
    contain: true,
		draggable: true,
		wrapAround: true,
		prevNextButtons: true,
		pageDots: true,
    selectedAttraction: 0.2,
    friction: 0.8
  }

  const setSlides = () => {
    $gallery.each(function() {
      const $this = $(this)
      const $slides = $this.find('.areas__area__gallery__slide')
      if ($slides.length > 1) $this.flickity(galleryOptions)
    })
  }

  const scrollToArea = $trigger => {
    const target = $trigger.data('trigger');
    const $target = $(`.areas__area[data-target="${target}"]`)
    const targetPos = $target.offset().top;
    $('html, body').animate({ scrollTop: targetPos - 120 }, 2000);
  }

  const navigateToArtist = $target => {}

	const eventListeners = () => {
		$navItem.click(function() {
      scrollToArea($(this))
    })
    $artistTrigger.click(function() {
      navigateToArtist($(this))
    })
	}

	pub.init = () => {
		eventListeners()
    setSlides()
	}

	return pub
})()
//  ***********************
//  $$ Document ready
//  ***********************
$(function() {

	window.windowWidth = window.innerWidth;
	window.windowHeight = window.innerHeight;

	window.isiPhone = navigator.userAgent.toLowerCase().indexOf('iphone');
	window.isiPad = navigator.userAgent.toLowerCase().indexOf('ipad');
	window.isiPod = navigator.userAgent.toLowerCase().indexOf('ipod');
	window.isAndroid = function(){ return /Android/i.test(navigator.userAgent); }
	window.getAndroidVersion = function(ua) {
		ua = (ua || navigator.userAgent).toLowerCase();
		var match = ua.match(/android\s([0-9\.]*)/);
		return match ? match[1] : false;
	};

	window.isIOS = false;
	if ( window.isiPhone > 0 || window.isiPad > 0 || window.isiPod > 0 ) {
		window.isIOS = true;
		$('html').addClass('iOS');
	}

	window.tablet = 640;
	window.tabletWide = 800;
	window.desktop = 1024;

	window.isTouch = false;
	if (('ontouchstart' in document.documentElement) || Modernizr.touchevents){
		window.isTouch = true;
		// isTouch = true;
	}

	if (isTouch || Modernizr.touchevents) {
		$('html').addClass('touch');
	}

	if (window.isAndroid()) {
		$( 'html' ).addClass( 'android' );
	}

	if (parseInt(getAndroidVersion(), 10) == 6) {
		$( 'html' ).addClass( 'android6' );
	}


	// Functions here

	nav.init();
	highlights.init();
	lineup.init();
	artist.init();
	areas.init();
	music.init();
	gallery.init();
	info.init();

});

//  ***********************
//  $$ Smart resize
//  ***********************
(function ($, sr) {
	var debounce = function (func, threshold, execAsap) {
		var timeout;
		return function debounced() {
			var obj = this,
				args = arguments;
			function delayed() {
				if (!execAsap) {
					func.apply(obj, args);
				}
				timeout = null;
			}
			if (timeout) {
				clearTimeout(timeout);
			} else if (execAsap) {
				func.apply(obj, args);
			}
			timeout = setTimeout(delayed, threshold || 500);
		};
	};
	jQuery.fn[sr] = function (fn) {
		return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
	};
})(jQuery, 'smartresize'); // End smartresize


$(window).smartresize(function () {
	window.newWindowWidth = window.innerWidth;
	window.newWindowHeight = window.innerHeight;

	// Functions here

	if (window.windowWidth < 1024 && window.newWindowWidth >= 1024 && !$('.info__nav__item').length) {
		info.showFirst()
	}
	if (window.windowWidth >= 1024 && window.newWindowWidth < 1024) {
		info.hideContent()
	}

	window.windowWidth = window.innerWidth;
	window.windowHeight = window.innerHeight;
});
});// jQuery end
// no writting in this file